var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-parents-page"},[_c('GgPage',{attrs:{"pageType":"2","title":"Parents"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('gg-flex-menus',{attrs:{"menus":_vm.menuDatas,"default-active":_vm.activeIndex},on:{"select":_vm.menuSelect}})]},proxy:true}])},[_c('div',{staticStyle:{"height":"100%"}},[_c('edoovo-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.usersData.isLoading),expression:"usersData.isLoading"}],ref:"multipleTable",attrs:{"data":_vm.usersData.tableData,"title":"Parents","tooltip-effect":"dark","default-value":"-","rowKey":"id","sort":_vm.usersData.sort},on:{"sortChange":_vm._onSortChange}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"200px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No parents yet.")])]),(_vm.filterRenderData.length)?_c('div',{staticClass:"list-filterBox",attrs:{"slot":"filter"},slot:"filter"},[_c('BaseFilter',{attrs:{"renderData":_vm.filterRenderData,"multKeys":[]},on:{"filterCallback":_vm._filterCallback}})],1):_vm._e(),_c('edoovo-pagination',{attrs:{"slot":"pagination","current-page":_vm.query.current,"page-size":_vm.query.size,"total":_vm.usersData.totalNum},on:{"size-change":_vm._pageSizeChange,"current-change":_vm._pageChange},slot:"pagination"}),_c('edoovo-table-column',{staticClass:"table-cell-box",attrs:{"size":"L","label":"Name","prop":"firstName","sortName":"firstName","tooltip":true,"getTooltipText":function (row) { return ((row.firstName) + " " + (row.lastName)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-cell-box",on:{"click":function($event){return _vm.linkTo(row)}}},[_c('head-img',{staticClass:"table-cell-img",attrs:{"src":row.photo,"firstName":row.firstName,"lastName":row.lastName,"line-height":"32"}}),_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(row.firstName)+" "+_vm._s(row.lastName))])],1)]}}])}),_c('edoovo-table-column',{attrs:{"size":"L","label":"Email","prop":"email","sortName":"email"}}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Connections","prop":"connections"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-cell-box",on:{"click":function($event){return _vm.linkToConnections(row)}}},[_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(row.connections))])])]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Status","prop":"status","getTooltipText":function (row) { return ("" + (row.status=='suspend'?'suspended':(row.status?row.status[0].toUpperCase()+row.status.substring(1):'-'))); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status=='suspend'?'suspended':(row.status?row.status[0].toUpperCase()+row.status.substring(1):'-'))+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"L","label":"Last sign in ","prop":"lastLoginTime","getTooltipText":function (row) { return _vm.dateFormat(row.lastLoginTime); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dateFormat(row.lastLoginTime))+" ")]}}])}),_c('edoovo-table-column',{key:"action",attrs:{"size":"M"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 'active')?[_c('gg-dropdown',{staticClass:"dropdown-con"},[_vm._v(" More "),_c('template',{slot:"menus"},[_c('gg-dropdown-item',{on:{"click":function($event){return _vm.openMessageBox(_vm.messageBox.suspend, row.userId)}}},[_vm._v(" Suspend ")]),_c('gg-dropdown-item',{on:{"click":function($event){return _vm.openDialogPwd(row)}}},[_vm._v(" Reset password ")])],1)],2)]:_vm._e(),(row.status === 'suspended')?_c('el-tooltip',{attrs:{"content":"Reactivate the company account","popper-class":"edoovo-table-cell-tooltip","visible-arrow":false}},[_c('gg-submit-button',{on:{"click":function($event){return _vm.openMessageBox(_vm.messageBox.reactivate, row.userId)}}},[_vm._v(" Reactivate ")])],1):_vm._e()]}}])})],1)],1)]),_c('gg-Dialog',{ref:"resetPassword",attrs:{"visible":_vm.formDialogPwd.visible,"before-close":_vm.cancelResetPwd,"width":"580px"},on:{"update:visible":function($event){return _vm.$set(_vm.formDialogPwd, "visible", $event)}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Reset password for "),_c('span',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.formDialogPwd.name))])]),_c('div',[_c('ResetPassword',{on:{"sendEmail":_vm.resetByEmail},model:{value:(_vm.formDialogPwd.data),callback:function ($$v) {_vm.$set(_vm.formDialogPwd, "data", $$v)},expression:"formDialogPwd.data"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('gg-cancel-button',{on:{"click":_vm.cancelResetPwd}},[_vm._v("Cancel")]),_c('gg-submit-button',{attrs:{"disabled":_vm.formDialogPwd.isDisabled},on:{"click":_vm.handleResetPwd}},[_vm._v("Reset")])],1)]),_c('Snackbar',{attrs:{"visible":_vm.isSnackbarShow,"content":_vm.snackbarData},on:{"update:visible":function($event){_vm.isSnackbarShow=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }