
<template>
  <div class="admin-parents-page">
    <GgPage pageType="2" title="Parents">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <div style="height: 100%">
        <edoovo-table
          ref="multipleTable"
          :data="usersData.tableData"
          title="Parents"
          tooltip-effect="dark"
          default-value="-"
          rowKey="id"
          v-loading="usersData.isLoading"
          :sort="usersData.sort"
          @sortChange="_onSortChange"
        >
          <!-- ===== -->
          <!-- 空状态 -->
          <!-- ===== -->
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 200px;
              "
            >
              <img
                style="width: 100%"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No parents yet.</p>
          </div>
          <!-- ======= -->
          <!-- 分页组件 -->
          <!-- ======= -->
          <div
            v-if="filterRenderData.length"
            class="list-filterBox"
            slot="filter"
          >
            <BaseFilter
              @filterCallback="_filterCallback"
              :renderData="filterRenderData"
              :multKeys="[]"
            />
          </div>

          <edoovo-pagination
            slot="pagination"
            @size-change="_pageSizeChange"
            @current-change="_pageChange"
            :current-page="query.current"
            :page-size="query.size"
            :total="usersData.totalNum"
          />
          <edoovo-table-column
            size="L"
            label="Name"
            prop="firstName"
            sortName="firstName"
            class="table-cell-box"
            :tooltip="true"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.firstName} ${row.lastName}`"
          >
            <div class="table-cell-box" @click="linkTo(row)">
              <head-img
                class="table-cell-img"
                :src="row.photo"
                :firstName="row.firstName"
                :lastName="row.lastName"
                line-height="32"
              />
              <span class="table-cell-name">{{ row.firstName }} {{ row.lastName }}</span>
            </div>
          </edoovo-table-column>
     <!--     <edoovo-table-column
            size="M"
            label="Role"
            prop="releationship"
            sortName="releationship"
          /> -->
          <edoovo-table-column
            size="L"
            label="Email"
            prop="email"
            sortName="email"
          />

        <!--  <edoovo-table-column
            size="M"
            label="Phone"
            prop="phone"
            v-slot="{ row }"
            :getTooltipText="(row) => `${ row.countryCode?row.countryCode:'-'} ${row.phoneNumber?row.phoneNumber:''}`"
          >
            {{ row.countryCode?row.countryCode:'-' }} {{ row.phoneNumber?row.phoneNumber:'' }}
          </edoovo-table-column> -->
          <edoovo-table-column
            size="M"
            label="Connections"
            prop="connections"
             v-slot="{ row }"
          >
		  <div class="table-cell-box" @click="linkToConnections(row)">
		   
		    <span class="table-cell-name">{{ row.connections }}</span>
		  </div>
		  </edoovo-table-column>
          <edoovo-table-column
            size="M"
            label="Status"
            prop="status"
			 v-slot="{ row }"
			 :getTooltipText="(row) => `${row.status=='suspend'?'suspended':(row.status?row.status[0].toUpperCase()+row.status.substring(1):'-')}`"
          >
		  {{row.status=='suspend'?'suspended':(row.status?row.status[0].toUpperCase()+row.status.substring(1):'-')}}
		   </edoovo-table-column>
          <edoovo-table-column
            size="L"
            label="Last sign in "
            prop="lastLoginTime"
            v-slot="{ row }"
			 :getTooltipText="(row) => dateFormat(row.lastLoginTime)"
          >
            {{dateFormat(row.lastLoginTime)}}
          </edoovo-table-column>
          <edoovo-table-column size="M" key="action" v-slot="{ row }">
            <!-- <baseButton type="primary">Edit</baseButton> -->
            <template v-if="row.status === 'active'">
              <gg-dropdown class="dropdown-con">
                More
                <template slot="menus">
					<gg-dropdown-item
					  @click="openMessageBox(messageBox.suspend, row.userId)"
					>
					  Suspend
					</gg-dropdown-item>
                <!--  <el-tooltip
                    content=""
                    popper-class="edoovo-table-cell-tooltip"
                    :visible-arrow="false"
                  >
                    
                  </el-tooltip> -->
				  <gg-dropdown-item @click="openDialogPwd(row)">
				    Reset password
				  </gg-dropdown-item>
                 <!-- <el-tooltip
                    content="Reset password"
                    popper-class="edoovo-table-cell-tooltip"
                    :visible-arrow="false"
                  >
                   
                  </el-tooltip> -->
                </template>
              </gg-dropdown>
            </template>
            <el-tooltip
              v-if="row.status === 'suspended'"
              content="Reactivate the company account"
              popper-class="edoovo-table-cell-tooltip"
              :visible-arrow="false"
            >
              <gg-submit-button @click="openMessageBox(messageBox.reactivate, row.userId)">
                Reactivate
              </gg-submit-button>
            </el-tooltip>
          </edoovo-table-column>
        </edoovo-table>
      </div>
    </GgPage>
    <!-- resetpassword dialog -->
    <gg-Dialog
      :visible.sync="formDialogPwd.visible"
      :before-close="cancelResetPwd"
      width="580px"
      ref="resetPassword"
    >
      <div slot="title">
        Reset password for
        <span class="title-bold">{{ formDialogPwd.name }}</span>
      </div>
      <div>
        <ResetPassword
          @sendEmail="resetByEmail"
          v-model="formDialogPwd.data"
        ></ResetPassword>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="cancelResetPwd">Cancel</gg-cancel-button>
        <gg-submit-button
          :disabled="formDialogPwd.isDisabled"
          @click="handleResetPwd"
          >Reset</gg-submit-button
        >
      </span>
    </gg-Dialog>
    <Snackbar :visible.sync="isSnackbarShow" :content="snackbarData" />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from "moment";
export default {
  data() {
    return {
      query: {
        status: "",
        current: 1,
        size: 20,
      },
      menuDatas: [
        {
          name: "Parents",
          key: "",
          active: true,
          children: [
            {
              name: "Active",
              key: "active",
            },
            {
              name: "Suspended",
              key: "suspended",
            },
          ],
        },
      ],
      activeIndex: "",
      usersData: {
        ref: "usersTable",
        totalNum: 0,
        sort: { name: "", type: "" },
        isLoading: false,
        tableTitle: "Parents",
        tableData: [],
      },
      filterRenderData: [
        { filterKey: "name", type: "input", title: "Name" },
        { filterKey: "email", type: "input", title: "Email" },
        {
          filterKey: "role",
          type: "checkbox",
          checkboxData: [
            { key: "Father", value: "Father" },
            { key: "Mother", value: "Mother" },
            { key: "Guardian", value: "Guardian" },
          ],
          title: "Relationship"
        },
      ],
      isSnackbarShow: false,
      formDialogPwd: {
        visible: false,
        data: "",
        autoSend: true,
        name: "",
        id: "",
        isDisabled: false,
      },
      messageBox: {
        suspend: {
          title: "Suspend parent ?",
          content:
            "Once suspended, the parent will not be able to access the apps.",
          confirmButtonText: "Suspend",
          snackbarData: "Parent has been suspended",
        },
        reactivate: {
          title: "Reactivate parent ?",
          content:
            "Once reactivated, the parent will be able to access the apps.",
          confirmButtonText: "Reactivate",
          snackbarData: "Parent has been reactivated",
        },
      },
      snackbarData: "",
    };
  },
  watch: {
    "formDialogPwd.data": function (res) {
      this.formDialogPwd.isDisabled = res.length < 8;
    },
  },
  created() {
    this._getData();
	this._getRelationshipData()
  },
  methods: {    
	  _getRelationshipData(){
		  Ajax.get("/usermanage/parent/getAllRelationship", {
		  }).then((res) => {
		    if (res.code === "0000") {
				this.filterRenderData.forEach((it)=>{
					it.checkboxData=[]
					if(it.filterKey == 'role'){
						let a
						res.data.forEach((item)=>{
							a={
								key:item,
								value:item
							}
							it.checkboxData.push(a)
						})
					}
				})
				
		    }
		  });
	  },
    dateFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      if (key === "active") {
        this.query.status = "active";
      } else if (key === "suspended") {
        this.query.status = "suspended";
      } else {
        this.query.status = "";
      }
      this._getData();
    },
    _getData() {
      Ajax.post("/usermanage/parent/selectParentByAllId", this.query).then(
        (res) => {
          let data = res.data || {};
          this.usersData.tableData = data.records || [];
		  this.usersData.totalNum = data.total
        }
      );
    },
    _pageChange(val) {
      this.query.current = val;
      this._getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this._getData();
    },
    _filterCallback(data) {
      let obj = {};
      if (data.length == 0) {
        this.query = {
          size: 10,
          current: 1,
          status: "",
        };
      }

      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });

      this.query = {
        size: 20,
        current: 1,
        status: "",
      };
      this.query = Object.assign(obj, this.query);
      this._getData();
    },
    _onSortChange(sort) {
      this.usersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      this._getData();
    },
    linkTo(row) {
      // 把每条数据的userid存下来，parentinfo页面去取
      sessionStorage.setItem("parentUserId", row.id);
	  this.$store.commit("SET_BREADCRUMDATA", [
	    {
	      routeName: "parents",
	      name: "Parents",
	    },
	    {
	      name: `${row.firstName} ${row.lastName}`,
	    }
	  ]);
      this.$router.push("/parentsDetail/parentsInfo");
    },
    linkToConnections(row) {
      // 把每条数据的userid存下来，connections页面去取
      sessionStorage.setItem("parentUserId", row.id);
	  this.$store.commit("SET_BREADCRUMDATA", [
	    {
	      routeName: "parents",
	      name: "Parents",
	    },
	    {
	      name: `${row.firstName} ${row.lastName}`,
	    }
	  ]);
      this.$router.push("/parentsDetail/parentsInfo");
    },
    openMessageBox(messageObj, userId) {
      this.$confirm(messageObj.content, messageObj.title, {
        cancelButtonText: "Cancel",
        confirmButtonText: messageObj.confirmButtonText,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        showClose: false,
      }).then(() => {
        let actionStatus = "";
        if (messageObj.confirmButtonText === "Suspend") {
          actionStatus = "suspended";
        } else if (messageObj.confirmButtonText === "Reactivate") {
          actionStatus = "active";
        }
        Ajax.post("/usermanage/superAdmin/updateStatus", {
          status: actionStatus,
          userId: userId,
        }).then((res) => {
          if (res.code === "0000") {
            this._getData();
          }
        });
        this.handleSuspend(messageObj.snackbarData);
      });
    },
    handleSuspend(snackbarData) {
      this.isSnackbarShow = true;
      this.snackbarData = snackbarData;
    },
    resetByEmail(val) {
      this.formDialogPwd.autoSend = val;
      if (val || this.formDialogPwd.data.length > 8) {
        this.formDialogPwd.isDisabled = false;
      } else {
        this.formDialogPwd.isDisabled = true;
      }
    },
    openDialogPwd(row) {
      this.formDialogPwd.isDisabled = false;
      this.formDialogPwd.name = `${row.firstName} ${row.lastName}`;
      this.formDialogPwd.id = row.id;
      this.formDialogPwd.autoSend = true;
      this.formDialogPwd.isDisabled = false;
      this.formDialogPwd.visible = true;
    },
    cancelResetPwd() {
      this.formDialogPwd.visible = false;
      this.formDialogPwd.data = "";
    },
    handleResetPwd() {
      let isAuto = this.formDialogPwd.autoSend ? "1" : "0";
      this.formDialogPwd.visible = false;
      Ajax.post("/usermanage/password/resetPasswd", {
        userId: this.formDialogPwd.id,
        newPasswd: this.formDialogPwd.data,
        isAuto: isAuto,
      }).then((res) => {
        if (res.code === "0000") {
          this.handleSuspend("Password has been reset");
          this.formDialogPwd.data = "";
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.admin-parents-page {
  .createBtnBox {
    padding-left: 8px;
    padding-bottom: 24px;
  }
  /deep/ .cursor {
    cursor: pointer;
  }
}
</style>
